













































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';

import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import Card from '@/shared/resources/components/cards/Card.vue';
import DeliveryFieldset from '@/app/modules/cart/components/partials/checkout/DeliveryFieldset.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import DeliveryDataFieldset from '@/app/modules/cart/components/partials/checkout/DeliveryDataFieldset.vue';
import CommentsFieldset from '@/app/modules/cart/components/partials/checkout/CommentsFieldset.vue';
import PaymentFieldset from '@/app/modules/cart/components/partials/checkout/PaymentFieldset.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import { onlineOrderValidationRules } from '@/app/modules/online-orders/config/onlineOrdersValidationRules';
import Order from '@/app/lib/order/Order';
import ContactDataFieldset from '@/app/modules/cart/components/partials/checkout/ContactDataFieldset.vue';
import ButtonsRow from '@/shared/resources/components/buttons/ButtonsRow.vue';
import GridSpacer from '@/shared/resources/components/grid/GridSpacer.vue';
import Sticky from '@/shared/resources/components/Sticky.vue';
import { PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';
import Payments from '@/shared/lib/support/Payments';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';

@Component({
  components: {
    Sticky,
    GridSpacer,
    ButtonsRow,
    ContactDataFieldset,
    Button,
    PaymentFieldset,
    CommentsFieldset,
    DeliveryDataFieldset,
    DeliveryFieldset,
    Card,
    GridCol,
    GridRow,
  },
})
export default class CartOrderCheckout extends Vue {
  /**
   * Validations
   */
  @Validations()
  private get rules() {
    return onlineOrderValidationRules(this.order!);
  }

  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel | null;

  /**
   * Data
   */
  private timeout: any | null = null;

  /**
   * Display getters
   */
  private get displayCheckoutForm(): boolean {
    return !!this.order;
  }

  private get displayContactDataCard(): boolean {
    return !!this.order!.paymentDelivery;
  }

  private get displayDeliveryDataCard(): boolean {
    return this.order!.requireAddress;
  }

  private get displayPaymentDataCard(): boolean {
    return this.order!.requirePayment;
  }

  private get displayCommentsFieldset(): boolean {
    return !!this.order!.paymentDelivery;
  }

  /**
   * Getters
   */
  private get paymentsDeliveries(): PaymentsTypeInterface[] {
    if (!Order.cartModel) {
      return [];
    }

    return Payments.getPaymentsTypesWithPricesCalculated(Order.cartModel.totalGross);
  }

  private get totals(): AmountsFormattedInterface {
    return Order.totalsFormatted;
  }

  private get disableGoToSummaryButton(): boolean {
    return !this.order!.paymentDelivery;
  }

  /**
   * Handlers
   */
  private onGoToSummaryButtonClick() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.$router.push({ name: 'cart.summary' });
  }

  /**
   * Watchers
   */
  @Watch('order', { deep: true })
  private watchOrderChange(order: OnlineOrderCoreModel) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      Order.syncOrder(order.toObject());
    }, 500);
  }
}
