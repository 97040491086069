















import { Component, Prop, Vue } from 'vue-property-decorator';

import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import FormField from '@/shared/resources/components/forms/FormField.vue';
import SelectField from '@/shared/resources/components/forms/fields/SelectField.vue';
import DeliveriesByPaymentsTypesSelectField from '@/app/modules/deliveries/components/fields/DeliveriesByPaymentsTypesSelectField.vue';
import { PaymentDeliveryInterface, PaymentsTypeInterface } from '@/modules/payments/interfaces/PaymentsTypesInterface';

@Component({
  components: {
    DeliveriesByPaymentsTypesSelectField,
    SelectField,
    FormField,
  },
})
export default class DeliveryFieldset extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
  @Prop() private paymentsDeliveries!: PaymentsTypeInterface[];

  /**
   * Handlers
   */
  private handleDeliveryPaymentInput(value: PaymentDeliveryInterface) {
    this.order.setPaymentDelivery(value);
  }
}
